<template>
  <div>
    <div class="modal_header">
      <el-row >
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple">
            <div class="title_h">
              {{ $t('message.new_role') }}
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button :loading="loadingButton" :disabled="loadingButton ? true : false" @click="submit(true)" type="success">{{ $t('message.save_and_close') }}</el-button>
              <el-button @click="resetForm('form')">{{ $t('message.close') }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>

    <el-form ref="form" status-icon :model="form" :rules="rules" :label-position="'right'"  class="aticler_m" >

      <el-row :gutter="20">
        <el-col :span="10">
          <el-form-item :label="columns.name.title" prop="name">
            <el-input v-model="form.name" autocomplete="off"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="10">
          <el-form-item :label="columns.slug.title" prop="slug">
            <el-input v-model="form.slug" autocomplete="off"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="10">
          <span>{{ $t('message.access_right') }}</span>
          <el-form-item>
            <el-tree
              :data="dataPermissions"
              show-checkbox
              node-key="id"
              ref="tree"
              :default-expanded-keys="[]"
              :default-checked-keys="[]"
              :props="defaultProps">
            </el-tree>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
<script>
import {mapGetters,mapActions} from 'vuex';
import form from '@/utils/mixins/form';
export default {
  mixins:[form],
  data(){
    return {
      defaultProps: {
        children: 'children',
        label: 'name'
      }
    }
  },
  created(){
    this.dataPermission()
  },
  computed: {
    ...mapGetters({
      dataPermissions: "permissions/parent_permissions",
      roles: 'roles/list',
      rules: 'roles/rules',
      model: 'roles/model',
      columns: 'roles/columns'
    })
  },
  methods:{
    ...mapActions({
      dataPermission: "permissions/parent_permissions",
      save: 'roles/store',
    }),
    submit(){
      let checkedPermissions = this.$refs.tree.getCheckedNodes();
      let perms = [];
      for (let key in checkedPermissions){
        if (checkedPermissions.hasOwnProperty(key)){
          let checkedPermission = checkedPermissions[key];
          perms.push(checkedPermission.id)
        }
      }
      this.form['permissions'] = perms;
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          this.save(this.form)
            .then(res => {
              this.loadingButton = false;
              this.$alert(res.message);
              this.parent().listChanged()
              this.resetForm('form');
              this.$refs.tree.setCheckedNodes([]);
            })
            .catch(err => {
              this.loadingButton = false;
              this.$notify({
                title: 'Ошибка',
                type: "error",
                offset: 130,
                message: err.error.message
              });
            });
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.$emit('c-close',{drawer: 'drawerCreate'});
    }
  },
}
</script>
